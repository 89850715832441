import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AuthService from './services/auth.service';
import LoginLayoutRoute from './layouts/LoginLayoutRoute';
import Login from './pages/login/Login';
import ForgottenPassword from './pages/login/ForgottenPassword';
import PasswordReset from './pages/login/PasswordReset';
import Wallet from './pages/Wallet';
import { PlanningContextProvider } from './contexts/app/PlanningContext';
import { WalletContextProvider } from './contexts/wallet/WalletContext';
import Planning from './pages/Planning';
import AccessPlanning from './pages/AccessPlanning';
import NotFound from './pages/NotFound';
import { NeedsContextProvider } from './contexts/needs/NeedsContext';
import { PlanningNeedsContextProvider } from './contexts/needs/PlanningNeedsContext';
import { VersionContextProvider } from './contexts/wallet/VersionContext';
import { getUserPreferences, getUserInformation, getTeamList } from './redux/slices/app/user.slice';
import { selectNotificationError, selectRequestError } from './redux/slices/app/error.slice';
import { notificationError, requestError } from './helpers/notification';

const Router = () => {
    const dispatch = useDispatch();
    const requestErrorObject = useSelector(selectRequestError);
    const notificationErrorObject = useSelector(selectNotificationError);

    /**
     * This function manages the application's initial loading and error handling.
     * It uses React's useEffect hook to perform side effects.
     *
     * @param {Object} dispatch - The Redux dispatch function to dispatch actions.
     * @param {Object} requestErrorObject - The Redux state object containing request error information.
     * @param {Object} notificationErrorObject - The Redux state object containing notification error information.
     * @param {Function} getUserInformation - A Redux action creator to fetch user information.
     * @param {Function} getUserPreferences - A Redux action creator to fetch user preferences.
     * @param {Function} requestError - A helper function to display request error notifications.
     * @param {Function} notificationError - A helper function to display notification error messages.
     *
     * @returns {null} This function does not return any value.
     */
    
    useEffect(() => {
        const onApplicationLoad = async () => {
            await dispatch(getUserInformation());
            await dispatch(getUserPreferences());
            dispatch(getTeamList());
        };
        onApplicationLoad();
    }, []);

    useEffect(() => {
        if (requestErrorObject) {
            requestError(requestErrorObject.error || '', requestErrorObject.title);
        }
    }, [requestErrorObject]);

    useEffect(() => {
        if (notificationErrorObject) {
            notificationError(
                notificationErrorObject.title,
                notificationErrorObject.description,
                notificationErrorObject.config
            );
        }
    }, [notificationErrorObject]);
    
    const homeComponent = AuthService.getAccessToken() ? (
        <WalletContextProvider>
            <VersionContextProvider>
                <NeedsContextProvider>
                    <PlanningContextProvider>
                        <PlanningNeedsContextProvider>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/programs" push />
                                </Route>
                                <Route exact path="/programs">
                                    <Wallet />
                                </Route>
                                <Route exact path="/planning">
                                    <Planning />
                                </Route>
                                <Route exact path="/planning/:viewId" component={Planning} />
                                <Route exact path="/program/:idPrg/:mode/planning/:idPlanning" component={AccessPlanning} />
                                <Route exact path="/home" />
                                <Route exact path="/features" />
                                <Route exact path="/pricing" />
                                <LoginLayoutRoute exact path="/login" Component={Login} />
                                <Route exact path="/not-found" component={NotFound} />
                                <Route path="*">
                                    <Redirect to="/not-found" />
                                </Route>
                            </Switch>
                        </PlanningNeedsContextProvider>
                    </PlanningContextProvider>
                </NeedsContextProvider>
            </VersionContextProvider>
        </WalletContextProvider>
    ) : (
        <Switch>
            <Route exact path="/">
                <Redirect to="/login" push />
            </Route>
            <Route exact path="/home/index.html" />
            <Route exact path="/features/index.html" />
            <Route exact path="/pricing/index.html" />
            <LoginLayoutRoute exact path="/login" Component={Login} />
            <LoginLayoutRoute exact path="/mot-de-passe-oublie" Component={ForgottenPassword} />
            <LoginLayoutRoute exact path="/reinitialisation-mot-de-passe" Component={PasswordReset} />
            <Route exact path="/program/:idPrg/planning/:idPlanning" component={AccessPlanning} />
            <Route exact path="/not-found" component={NotFound} />
            <Route path="*">
                <Redirect to="/not-found" />
            </Route>
        </Switch>
    );

    return <BrowserRouter forceRefresh={!AuthService.getAccessToken()}>{homeComponent}</BrowserRouter>;
};

export default Router;
